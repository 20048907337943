@use 'sass:color';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$STSL_SmartLocker_Utils_WebClient-primary: mat.define-palette(mat.$blue-palette, 700, 600, 900);
$STSL_SmartLocker_Utils_WebClient-accent: mat.define-palette(mat.$green-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$STSL_SmartLocker_Utils_WebClient-warn: mat.define-palette(mat.$red-palette);

$primary: mat.get-color-from-palette($STSL_SmartLocker_Utils_WebClient-primary, 700);
$accent: mat.get-color-from-palette($STSL_SmartLocker_Utils_WebClient-accent, A200);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$STSL_SmartLocker_Utils_WebClient-theme: mat.define-light-theme((
  color: (
    primary: $STSL_SmartLocker_Utils_WebClient-primary,
    accent: $STSL_SmartLocker_Utils_WebClient-accent,
    warn: $STSL_SmartLocker_Utils_WebClient-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($STSL_SmartLocker_Utils_WebClient-theme);

html {
  --colour-primary: #{$primary};
  --colour-accent: #{$accent};

  /*scrollbar-width: auto; 
  scrollbar-color: rgba(90, 90, 90, 0.6) transparent; */

  /* Styling the scrollbar for WebKit browsers */
::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  opacity: 1;
  border-radius: 10px; /* Rounded corners for the track */
}

::-webkit-scrollbar-thumb {
  background: #c7c7c7; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  opacity: 0.6;
}

::-webkit-scrollbar-thumb:hover {
  background: #c7c7c7; /* Color of the scrollbar thumb on hover */
}
  /*scrollbar-color: rgba(0, 0, 0, .75) color.change($primary, $alpha: .75);
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    background-color: color.change($primary, $alpha: .75);
    width: .5rem;
    height: .5rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .75);
  }*/
}


// Fix for alignment of Material buttons with Material inputs inside flex containers
.flex {
  > .align-button-with-input {
    padding-bottom: 1.34375em;

    > .mat-mdc-button-base {
      width: 100%;
      height: max(calc(36px + .4em), 100%);
    }
  }
}

.mat-mdc-tab-group,
.mat-mdc-tab-body-wrapper {
  /* height: 100% !important; */
  flex-grow: 1;
}

.mat-mdc-tab-body {
  background-color: white;
}

.mat-sort-header-content {
  text-align: unset !important;
}

.mat-mdc-tab-header {
  margin-bottom: .25rem;
}

@media print {
  input,
  button,
  label,
  .mat-mdc-tab-header,
  .mat-mdc-paginator {
    display: none !important;
  }
}

// Fix for very dumb Angular Material issue of unaligned menu item text
// when text wraps.. Material Design team really baffle me sometimes..
// why do they let their work experience kids write the framework?
.mat-mdc-menu-item .mat-icon {
  flex-shrink: 0;
}