@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fix vertical alignment issues between TW and MUI icons */
html {
  line-height: normal;
}

.tooltip-list {
  white-space: pre;

  .mdc-tooltip__surface 
  { 
    max-width: unset; /* remove width constraints */
    line-height: 1.5 !important;
  }
}

.tooltip-text {
  white-space: normal;

  .mdc-tooltip__surface 
  { 
    max-width: 500px; /* enforce width constraints */
    line-height: 1.5 !important;
  }
}

